import React, { useState, useEffect } from 'react';
import Hero from './Hero/Hero';
import About from './About/About';
import Projects from './Projects/Projects';
import Contact from './Contact/Contact';
import Footer from './Footer/Footer';
import PuffLoader from "react-spinners/PuffLoader";

import { PortfolioProvider } from '../context/context';

import { heroData, aboutData, projectsData, sideProjectsData, contactData, footerData } from '../mock/data';
import Animate from 'react-smooth/lib/Animate';
import Particles from 'react-particles-js';
import particles from '../particles/particles';





function App() {
  const [hero, setHero] = useState({});
  const [about, setAbout] = useState({});
  const [projects, setProjects] = useState([]);
  const [sideProjects, setSideProjects] = useState([]);
  const [contact, setContact] = useState({});
  const [footer, setFooter] = useState({});
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
 

  useEffect(() => {
    setHero({ ...heroData });
    setAbout({ ...aboutData });
    setProjects([...projectsData]);
    setSideProjects([...sideProjectsData]);
    setContact({ ...contactData });
    setFooter({ ...footerData });
    const timer = setTimeout(() => {
      setLoading(false);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  if(loading === true){
    return(
      <div className="loading-header">
        <PuffLoader
          size={'40vw'}
          color={"white"}
          loading={true}
        />
      </div>
    )    
  }
  return (
    <PortfolioProvider value={{ hero, about, projects,sideProjects, contact, footer }}>
      <Animate to="1" from="0" attributeName="opacity">
        <Particles params={particles} className="particle" />
        <Hero />
        <About />
        <Projects />
        <Contact />
      </Animate>
      <Footer />
    </PortfolioProvider>
  );
}

export default App;
